.zg-container-content {
  height: 100%;
  overflow: auto;
}
.area-list {
  display: none !important;
  flex-wrap: wrap;
  width: 60vw;
  .item {
    color: #778ca2;
    padding: 0 15px;
    border-right: 1px solid @border-color-base;
    margin-bottom: 20px;
    cursor: pointer;
    &.active {
      color: #5473e8;
    }
  }
  .item:first-child {
    // padding-left: 0;
  }
  .item:last-child {
    padding-right: 0;
    border-right: 0;
  }
}
.area-list.active {
  display: flex !important;
}
.set-wrap {
  .big-block {
    width: 22px;
    height: 22px;
    background: #e8ebf9;
    margin-left: 20px;
    margin-right: 8px;
    display: inline-block;
    cursor: pointer;
    &.active {
      background: #5473e8;
    }
  }
  .litle-block-wrap {
    cursor: pointer;
    flex-wrap: wrap;
    width: 22px;
    height: 22px;
    display: inline-block;
  }
  .litle-block {
    display: inline-block;
    width: 10px;
    height: 10px;
    background: #e8ebf9;
    &.active {
      background: #5473e8;
    }
  }
  .block1 {
    margin: 0 2px 1px 0;
  }
  .block2 {
    margin: 0 0px 1px 0;
  }
  .block3 {
    margin: 0 2px 0px 0;
  }
}
.video-item-wrap {
  .video-item {
    background: #e8ebf9;
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    .title-wrap {
      margin-bottom: 10px;
      .left {
        .icon {
          padding-top: 0;
          font-size: 20px;
          color: #a0adbc;
        }
        .desc {
          margin-left: 10px;
          color: #252631;
        }
      }
      .right {
        .icon {
          padding-top: 0;
          font-size: 20px;
          color: #a0adbc;
        }
        .desc {
          margin-right: 10px;
          color: #778ca2;
          vertical-align: middle;
        }
      }
    }
    .video-info {
      background: black;
      width: 100%;
      overflow: hidden;
    }
  }
}
.video-item-wrap-multiple {
  .block-item {
    border-radius: 5px;
    border: 1px solid #fff;
    &.active {
      border: 1px solid red;
    }
  }
  .video-item {
    .video-info {
      height: 500px !important;
      width: 100%;
      box-sizing: border-box;
    }
    @media screen and (max-width: 2000px) {
      .video-info {
        height: 300px !important;
      }
    }
    @media screen and (max-width: 1400px) {
      .video-info {
        height: 200px !important;
      }
    }
  }
}
.video-item-wrap-single {
  .video-item {
    .video-info {
      height: 800px !important;
      overflow: hidden;
    }
    @media screen and (max-width: 2000px) {
      .video-info {
        height: 600px !important;
      }
    }
    @media screen and (max-width: 1400px) {
      .video-info {
        height: 360px !important;
      }
    }
  }
}
.commit-wrap {
  .img-wrap {
    margin-top: 10px;
    width: 790px;
    height: 450px;
    overflow: hidden;
    position: relative;
    .temp-canvas {
      top: 0;
      right: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
    .preview_canvas {
      width: 100%;
      height: 100%;
    }
  }
  .no-edit {
    padding: 0 10px;
    height: 36px;
    background: #e8ebf9;
    color: #252631;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .select-wrap {
    width: 100%;
    margin-bottom: 20px;
  }
  .btn-wrap {
    margin-top: 20px;
    .btn {
      flex: 1;
    }
    .btn1 {
      margin-right: 20px;
    }
  }
}
.canteen-list {
  height: 100%;
  padding: 20px 0 !important;
  background: #fff;
  overflow: hidden;
  .menu-list {
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
  }
  .menu-list::-webkit-scrollbar {
    width: 0;
  }
  .title-level-first {
    padding-left: 20px;
  }
  .search-wrap {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .ant-menu-submenu {
    background: #f6f7fc !important;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
    background: #e8ebf9 !important;
    color: #252631 !important;
  }
  .ant-menu-item {
    color: #252631 !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f6f7fc !important;
    color: @primary-color!important;
  }
  // .ant-menu .ant-menu-item::after{
  //   border-right: 0!important;
  //   content: none;
  // }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0 !important;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
  }
  .ant-menu-submenu-arrow {
    color: #ccc !important;
  }
}

.video-js-ys{
  width: 100%;
  height: 100%;
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;