.list-wrap {
  .title-wrap {
    margin-bottom: 20px;

    .tab-wrap {
      margin-right: 50px;
    }
  }

  .img-block {
    cursor: pointer;
    border: 1px solid @border-color-base;

    .none-wrap {
      height: 120px !important;
    }

    .ant-image,
    .img {
      width: 100%;
      height: 120px !important;
    }

    .price-line {
      padding: 2px 20px;
    }

    .price {
      color: #ea5752;
    }

    .name {
      padding: 2px 20px;
      color: #252631;
    }
  }
}

.detail-wrap {
  .line {
    border-bottom: 1px solid @border-color-base;
    margin: 24px 0;
  }

  .desc-wrap {
    background: #f6f7fc;
    padding: 20px;
    color: #778ca2;
  }

  .ant-image,
  .img {
    width: 180px;
    height: 100px;
  }
}

.dish-list_index {
  display: flex;
  flex-flow: row wrap;

  .dish-item {
    width: 160px;
    height: 160px;
    background: #ffffff;
    margin: 0px 15px 15px 0px;

    .item-img {
      height: 120px;
    }

    .item-img .img {
      width: 100%;
      height: 120px;
    }

    .item-cont {
      border-bottom: 1px solid #e9ecf7;
      border-left: 1px solid #e9ecf7;
      border-right: 1px solid #e9ecf7;
      padding: 8px 10px;

      .item-cont-top {
        margin-bottom: 6px;
        font-size: 15px;
      }

      .item-cont-btm {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;

        .btm-one {
          color: #ea5752;
        }

        .btm-two {
          color: #778ca2;
        }
      }
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;