.login-container {
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 100%;

  .img {
    height: 100%;
    width: 100%;
  }
}

.login-wrap {
  position: absolute;
  top: 20vh;
  left: 50%;
  margin-left: -500px;

  .left {
    width: 500px;
    height: 480px;
    background: rgba(0, 0, 0, 0.5);
    padding: 0 10px;
    overflow: hidden;
    color: white;

    .title {
      font-size: 30px;
      margin-top: 140px;
    }

    .desc-line {
      margin-top: 30px;
      font-size: 26px;

      .logo {
        height: 32px;
        margin-right: 10px;
      }
    }

    .footer {
      font-size: 14px;
      margin-top: 180px;
    }
  }

  .right {
    width: 500px;
    height: 480px;
    background: white;
    padding: 50px 90px;

    .title {
      color: #000;
      font-size: 20px;
    }

    .title-line {
      text-align: center;
      height: 10px;
      line-height: 5px;
    }

    .act-line {
      display: inline-block;
      width: 30px;
      height: 5px;
      background: #5473E8;
    }
  }

  .login-form {
    margin-top: 40px;

    .ant-input-affix-wrapper {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-radius: 0;
      padding: 15px 0;
    }

    .ant-input-affix-wrapper-focused {
      box-shadow: none;
    }

    .login-form-button {
      width: 100%;
      height: 40px;
      // margin-top: 30px;
    }
  }

  .label {
    display: inline-block;
    width: 80px;
  }

  .input {
    height: 40px;
    border: 0;
    background: #efefef;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;