.zg-container {
  .title_wrap {
    padding: 20px;

    .title_name {
      .ant-form-item {
        display: flex;
        flex-direction: column;

        .ant-form-item-label {
          text-align: left;
        }
      }
    }

    .submit_left {
      margin: 0 10px;
    }

    .images {
      width: 100px;
      height: 100px;
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;