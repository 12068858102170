
.item{
  color: #778CA2;
  padding: 0 15px;
  border-right: 1px solid @border-color-base;
  cursor: pointer;
  &.active{
    color: #5473E8;
  }
}
.item:first-child{
  padding-left: 0;
}
.item:last-child{
  padding-right: 0;
  border-right: 0;
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;