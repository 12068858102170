.container {
  width: 100%;
  height: 100%;
  position: relative;
  line-height: 14px;
}

.left {
  position: absolute;
  left: 20px;
  top: 20px;
  bottom: 20px;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 10px;
  z-index: 2;
  width: 400px;
  padding: 20px 14px;
  box-sizing: border-box;
}

.sortSelect {
  box-sizing: border-box;
}

.leftTitle {
  font-size: 28px;
  color: #161488;
}

.leftTip {
  font-size: 18px;
  color: #424093;
  margin-bottom: 30px;
  margin-top: 10px;
}

.leftLabel {
  color: #424093;
  width: 100px;
}

.tableHeader {
  background: #9293CA;
  color: white;
  padding: 5px;
  font-size: 12px;
}

.tableBodyLine {
  padding: 10px 5px;
  font-size: 12px;
  cursor: pointer;
}

.tableBodyLine.active {
  background: rgba(192, 192, 221, 0.9);
}

.bodyItem {
  flex: 1;
}

.greenBg {
  background: #1EB38B;
}

.blueBg {
  background: #3582FE;
}

.yellowBg {
  background: #DC8320;
}

.redBg {
  background: #e84d4f;
}

.greyBg {
  background: #A5A5A5;
}

.colorItem {
  margin-right: 20px;
  margin-bottom: 10px;
}

.colorBlock {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  margin-right: 10px;
}

.opeLine {
  margin-bottom: 10px;
}

.opeLineRight {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.headerItem {
  flex: 1;
}

.headerItem:not(:last-child) {
  margin-right: 4px;
}

.headerItem:last-child {
  text-align: right;
}

.headerLine {
  width: 100px;
  display: inline-block;
  margin-right: 4px;
}

.rateList {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 8px;
  width: 100px;
  background: #A5A5A5;
  margin-right: 4px;
}

.rateItem {
  display: inline-block;
  height: 8px;
}

.bodyItem:not(:last-child) {
  margin-right: 4px;
}

.bodyItem:last-child {
  text-align: right;
}

.right {
  position: absolute;
  right: 20px;
  top: 20px;
  bottom: 20px;
  background: rgba(133, 132, 199, 0.7);
  border-radius: 10px;
  z-index: 2;
  width: 560px;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}

@media screen and (max-width: 2000px) {
  .right {
    width: 400px !important;
  }

  .left {
    width: 260px !important;
  }

  .sortSelect {
    width: 130px;
  }
}

.rightTitleLine {
  margin-bottom: 30px;
}

.rightTitle {
  font-size: 28px;
  color: #fff;

}

.exportBtn {
  width: 65px;
  height: 30px;
  line-height: 30px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
  color: #37358a;
}

.blockTitle {
  font-size: 18px;
  color: #D2D2FF;
  position: relative;
  padding-left: 18px;
  margin-bottom: 16px;
}

.blockTitle::before {
  position: absolute;
  content: '';
  left: 0;
  top: -1px;
  width: 6px;
  height: 18px;
  opacity: 1;
  background: #D2D2FF;
  border-radius: 3px;
}

.colorBg {
  background: rgba(35, 32, 126, 1);
  padding: 16px;
}

.baseInfo {
  margin-bottom: 20px;
}

.baseLeft {
  margin-right: 10px;
}

.baseLeft,
.baseRight {
  flex: 1;
  overflow: hidden;
}

.baseItem {
  line-height: 24px;
  flex: 1;
  overflow: hidden;
}

.rightLabel {
  color: #A8A5FF;
  display: inline-block;
}

.rightValue {
  color: #fff;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dayStacChart {
  width: 50%;
  height: 200px;
  overflow: hidden;
}

/* .dayStacChart1 {
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
} */

.stacTitle {
  height: 34px;
  padding: 0 5px;
  text-align: center;
  line-height: 34px;
  background: #6662df;
  font-size: 14px;
  color: #ffffff;
}

.stacItem {
  flex: 1;
  overflow: hidden;
}

.stacItem:not(:last-child) {
  margin-right: 20px;
}

.stacLine {
  color: #fff;
  line-height: 28px;
  font-size: 12px;
}

.stacLineGrey {
  color: #A8A5FF;
}

.stacIndex {
  margin-right: 5px;
}

.stacName {
  overflow: hidden;
  white-space: nowrap;
  flex: 1;
  text-overflow: ellipsis;
}


.blockTitle3 {
  margin-top: 20px;
}


.legendItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  line-height: 20px;
}

.legendItem:first-child {
  margin-top: 0;
}

.legendSpan {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 14px;
}

.legendText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.legendNumber {
  margin-left: 10px;
}