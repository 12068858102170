.detial-wrap .info .info_item_img {
  cursor: pointer;
  margin-right: 10px;
}

.detial-wrap .info .info_img {
  display: flex;
  align-items: center;
}

.detial-wrap .info .info_img .ant-image {
  margin-right: 10px;
}

.detial-wrap .info .info_img .ant-image img {
  width: 100px;
  height: 100px;
}

.detial-wrap .info .info_item_head {
  line-height: 38px;
}

.detial-wrap .info .size_style {
  color: #000000;
  font-size: 18px;
  margin-bottom: 10px;
}

.detial-wrap .info .info_item_ds {
  line-height: 30px;
}

.detial-wrap .info .size_style {
  color: #000000;
  font-size: 18px;
  margin-bottom: 10px;
}

.detial-wrap .info .info_bot {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}

.detial-wrap .info .info_bot .info_item {
  margin-right: 30px;
}

.detial-wrap .info .info_bot .info_item .type {
  margin-bottom: 10px;
}


/* .info {
   .info_img {
     display: flex;

     .info_item_img {
       cursor: pointer;
       margin-right: 10px;

       img {
         width: 70px;
         height: 70px;
       }
     }
   }

   .info_item_head {
     line-height: 50px;
   }

   .info_item_ds {
     line-height: 30px;
   }

   .size_style {
     color: #000000;
     font-size: 18px;
     margin-bottom: 10px;
   }

   .info_bot {
     display: flex;
     flex-wrap: nowrap;

     .info_item {
       margin-right: 30px;

       .type {
         margin-bottom: 10px;
       }
     }
   }
 } */
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;