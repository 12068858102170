.wrap {
  height: 100%;
}

.ant-layout-header {
  padding-left: 25px !important;
  box-sizing: border-box;
  width: 100%;
}

.header-wrap {
  color: #ffffff;

  .title {
    cursor: pointer;
    font-size: 20px;
  }

  .log-out {
    cursor: pointer;
    font-size: 18px;
  }
}

.zg-bread-nav {
  height: 50px;
  line-height: 50px !important;
  padding: 0 40px !important;
  background: #fff !important;
  box-sizing: border-box;
}

.left-menu-wrap .ant-menu-vertical .ant-menu-item,
.left-menu-wrap .ant-menu-vertical-left .ant-menu-item,
.left-menu-wrap .ant-menu-vertical-right .ant-menu-item,
.left-menu-wrap .ant-menu-inline .ant-menu-item,
.left-menu-wrap .ant-menu-vertical .ant-menu-submenu-title,
.left-menu-wrap .ant-menu-vertical-left .ant-menu-submenu-title,
.left-menu-wrap .ant-menu-vertical-right .ant-menu-submenu-title,
.left-menu-wrap .ant-menu-inline .ant-menu-submenu-title {
  margin-top: 0;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  font-size: 20px !important;
}

.zg-container {
  padding: 20px;
}

.zg-container-content {
  padding: 20px;
  background: #fff;
}

.vertical-line {
  display: inline-block;
  width: 4px;
  height: 14px;
  background: #a4abc9;
  border-radius: 3px;
  margin-right: 12px;
}

.zg-flex-row {
  display: flex;
  flex-direction: row;
}

.zg-space-between {
  justify-content: space-between;
}

.base-title {
  color: #000000;
}

.base-msg {
  color: #778ca2;
}

.model-title {
  font-size: 28rpx;
  padding-bottom: 56rpx;
}

div {
  scrollbar-width: none;
  /* firefox */
  -ms-overflow-style: none;
  /* IE 10+ */
}

div::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}

#loading-spin {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  font-size: 20px;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;