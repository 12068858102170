.popBox {
  color: white;
  line-height: 14px;
}

.leftBox {
  width: 400px;
  background: #2B2971;
  padding: 20px;
}

.hand {}

.infoLine {
  margin-bottom: 30px;
}

.infoImg {
  margin-top: 20px;
  width: 300px;
}

.rightBox {
  width: 700px;
  background: #322E7F;
  padding: 20px;
  padding-top: 40px;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  transform: scaleX(1.5);
  cursor: pointer;
}

.recBlock {
  font-size: 16px;
  height: 148px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  flex: 1;
  cursor: pointer;
}

.recBlock:last-child {
  margin-right: 0;
}

.level {
  margin-top: 20px;
  width: 60px;
  height: 60px;
  line-height: 56px;
  overflow: hidden;
  border-radius: 50%;
  background: #3582fe;
  font-size: 40px;
  text-align: center;
}

.scoreLine {
  margin-top: 20px;
  margin-bottom: 10px;
  line-height: 40px;
}

.unit {
  display: inline-block;
  line-height: 50px;
  margin-left: 2px;
}

.score {
  font-size: 40px;
}

.workBox {
  margin: 20px 0;
  margin-top: 40px;
}

.workList {
  flex-wrap: wrap;
}

.workItem {
  height: 46px;
  background: #3a3888;
  border: 1px solid rgba(95, 88, 255, 0.76);
  padding: 0 18px;
  color: #E1E1E7;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
  width: 150px;
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;
}

.workItem:nth-child(4n) {
  margin-right: 0;
}

.workItem .workValue {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 25px;
  color: white;
}

.warnItem {
  height: 56px;
  background: #3a3888;
  padding: 0 18px;
  color: #fff;
  margin-right: 20px;
  align-items: center;
  justify-content: space-between;
  width: 205px;
  box-sizing: border-box;
  margin-bottom: 20px;
  cursor: pointer;
}

.warnItem:nth-child(3n) {
  margin-right: 0;
}

.warnList {
  flex-wrap: wrap;
}

.warnIcon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  background: #292589;
  font-size: 16px;
}

.warnIcon1 {
  font-size: 20px;
}

.moreBtn {
  width: 140px;
  height: 40px;
  background-image: url('../../assets/images/board/more.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.moreBox {
  justify-content: flex-end;
  margin-top: 40px;
}

.noWrap {
  margin-top: 20px;
}

.noImg {
  width: 60px;
  margin-bottom: 20px;
}