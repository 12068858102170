.fridge-daily {
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 20px;
  .fridge-tit {
    display: flex;
    flex-flow: row nowrap;
    .tit-img {
      margin-right: 20px;
    }
    .tit-cont {
      display: flex;
      flex-flow: column nowrap;
      .tit-ft-top1 {
        font-weight: 500;
      }
      .tit-ft-top2 {
        font-size: 12px;
        color: #999999;
      }
      .tit-ft-down {
        font-size: 12px;
        color: #999999;
        margin-top: 3px;
      }
    }
    .tit-zx-state {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 28px;
      color: #ffffff;
      margin-left: 20px;
      background: #23bcca;
    }
    .tit-lx-state {
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 28px;
      color: #333333;
      background: #e8ebf9;
      margin-left: 20px;
    }
  }
  .fridge-table {
    margin-top: 20px;
  }
  .foot-time {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    color: #999999;
    font-size: 14px;
    margin-top: 20px;
  }
}
.table-tag {
  width: 51px;
  height: 25px;
  background: #ffffff;
  border: 1px solid #c0d3e8;
  border-radius: 5px;
  color: #23bcca;
  font-size: 12px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.table-flag {
  width: 76px;
  height: 24px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  margin-top: 6px;
}
.green-bg {
  background: #56cea5;
}
.blue-bg {
  background: #117cef;
}
.yellow-bg {
  background: #ffd58b;
}
.orange-bg {
  background: #f89843;
}
.lightRed-bg {
  background: #f85d43;
}
.yz-bg {
  background: #bb0000;
}
.red-bg {
  background: #e92020;
}
.grey-bg {
  background: #999999;
}

.green-color {
  color: #23bcca;
}
.blue-color {
  color: #117cef;
}
.yellow-color {
  color: #ffd58b;
}
.orange-color {
  color: #f89843;
}
.lightRed-color {
  color: #f85d43;
}
.red-color {
  color: #e92020;
}
.grey-color {
  color: #999999;
}
.white-color {
  color: #ffffff;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;