.title-name {
  color: rgba(56, 56, 56, 1);
  font-size: 14px;
  padding-bottom: 5px;
}

.title-basic {
  display: flex;
  flex-direction: row;

  .title-img {
    padding-right: 15px;

    img {
      border-radius: 5px;
    }
  }

  .title-info {
    color: rgba(128, 128, 128, 1);
    font-size: 12px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;