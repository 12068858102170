.rank-cont {
  display: flex;
  flex-flow: column nowrap;
  .tit-right {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin-left: auto;
    .right-one {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 26px;
      background: #5372e3;
      color: #ffffff;
    }
  }
  .rank-list {
    display: flex;
    flex-flow: column nowrap;
    padding-right: 16px;
    .list-item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      margin-bottom: 26px;
      .list-row-left {
        display: flex;
        flex-flow: row nowrap;
        .list-left-tag {
          width: 22px;
          height: 22px;
          border-radius: 50%;
          color: #ffffff;
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          margin-top: 4px;
          margin-right: 16px;
          font-size: 16px;
        }
        .list-left-cln {
          display: flex;
          flex-flow: column;
          .list-left-ft1 {
            font-size: 14px;
            margin-bottom: 6px;
          }
          .list-left-ft2 {
            font-size: 12px;
          }
        }
      }
      .list-row-right {
        font-size: 14px;
        font-weight: 400;
        color: #5473e8;
      }
    }
    .blue-bg {
      background: #5473e8;
    }
    .green-bg {
      background: #4cccd4;
    }
    .yellow-bg {
      background: #ff9f24;
    }
    .grey-bg {
      background: #a6b6c6;
    }
  }
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;