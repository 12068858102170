.zg-container {
  height: calc(100% - 80px);
}
.canteen-list {
  height: 100%;
  padding: 20px 0 !important;
  background: #fff;
  overflow: hidden;
  .menu-list {
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
  }
  .menu-list::-webkit-scrollbar {
    width: 0;
  }
  .title-level-first {
    padding-left: 20px;
  }
  .search-wrap {
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .ant-menu-submenu {
    background: #f6f7fc !important;
  }
  .ant-menu-inline .ant-menu-submenu-title {
    margin-top: 0 !important;
    background: #e8ebf9 !important;
    color: #252631 !important;
  }
  .ant-menu-item {
    color: #252631 !important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: #f6f7fc !important;
    color: @primary-color!important;
  }
  // .ant-menu .ant-menu-item::after{
  //   border-right: 0!important;
  //   content: none;
  // }
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: 0 !important;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    width: 100% !important;
  }
  .ant-menu-submenu-arrow {
    color: #ccc !important;
  }
}
.index-wrap {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.stac-info-wrap {
  height: calc(100% - 160px);
  margin-top: 12px;
  .card-wrap {
    color: #778ca2;
    border-radius: 5px;
    cursor: pointer;
    .line1 {
      background: #f6f7fc;
      padding: 0px 22px;
      height: 100px;
      border-radius: 5px 5px 0 0;
      .icon {
        font-size: 44px;
        color: #778ca2;
      }
      .number {
        font-size: 20px;
        color: #252631;
      }
      .desc {
        color: #a6b6c6;
      }
    }
    @media screen and (max-width: 1600px) {
      .line1 {
        padding: 0 5px!important;
      }
    }
    .line2 {
      padding: 12px 18px;
      background: #e9ecf7;
      border-radius: 0 0 5px 5px;
    }
  }
  .row-line {
    margin-bottom: 30px;
    .cell-item {
      height: 50px;
      background: #f6f7fc;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0 14px;
      cursor: pointer;
      .icon {
        font-size: 20px;
        color: #778ca2;
        padding-top: 0;
      }
      .icon-wrap {
        width: 34px;
        height: 34px;
        background: #eceff8;
        border-radius: 50%;
        text-align: center;
        line-height: 34px;
      }
      .icon-wrap-green {
        background: #e7fafb;
      }
      .icon-wrap-yellow {
        background: #fff7e9;
      }
      .tag {
        display: inline-block;
        width: 26px;
        height: 26px;
        color: #fff;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
      }
      .blue {
        background: @success-color;
      }
      .yellow {
        background: @warning-color;
      }
    }
  }
  .row-line-warn {
    .cell-item {
      height: 50px;
      background: #fff;
      border: 1px solid @border-color-base;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 0 14px;
      cursor: pointer;
      margin-bottom: 10px;
      .tag {
        color: #252631;
        font-size: 18px;
      }
    }
  }
}
.base-info-wrap {
  .card-wrap {
    position: relative;
  }
  .card-wrap:not(:last-child)::after {
    position: absolute;
    right: 0;
    top: 10px;
    width: 1px;
    height: 30px;
    content: "";
    background: @border-color-base;
  }
  .label {
    color: #778ca2;
    margin-right: 15px!important;
  }
  .name {
    color: #252631;
    margin-bottom: 5px;
    font-size: 16px;
  }
  .flag {
    display: inline-block;
    width: 26px;
    height: 26px;
    color: #fff;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
    margin-left: 16px;
    background: @warning-color;
  }
  .desc-tag1 {
    color: #fff;
    padding: 0 10px;
    height: 20px;
    background: @success-color;
  }
  .desc-tag2 {
    color: #fff;
    padding: 0 10px;
    height: 20px;
    background: @primary-color;
    margin-left: 8px;
  }
}
.video-wrap {
  .cell-item {
    height: 50px;
    background: #f6f7fc;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 8px;
    cursor: pointer;
    .icon {
      font-size: 20px;
      margin-right: 4px;
      color: #778ca2;
      padding-top: 0;
    }
    .tag {
      display: inline-block;
      width: 26px;
      height: 26px;
      color: #fff;
      text-align: center;
      line-height: 26px;
      border-radius: 50%;
    }
    .blue {
      background: @success-color;
    }
    .yellow {
      background: @warning-color;
    }
  }
}
.score-wrap {
  margin-top: 12px;
  height: calc(100% - 160px);
  background: white;
  .desc-line {
    color: #778ca2;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .desc {
    color: #778ca2;
    margin-bottom: 40px;
  }
  .chart-wrap {
    position: relative;
    .desc-wrap {
      position: absolute;
      top: 15px;
      left: 15px;
      background: #e9ecf7;
      height: 150px;
      width: 150px;
      border-radius: 50%;
      .chart-number {
        font-size: 28px;
        color: #5473e8;
      }
      .unit {
        font-size: 18px;
        color: #5473e8;
      }
      .chart-desc {
        font-size: 18px;
        color: #5473e8;
      }
    }
  }
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;