.detail-wrap {
  .card-info1 {
    .ant-image1 {
      display: flex;
      justify-content: center;
      align-items: center;
      .ant-image {
        width: 100px;
        height: auto;
      }
    }
    .card-info2 .ant-image {
      .ant-image-mask {
        position: absolute !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 78px !important;
      }
    }
    .none-wrap1 {
      background: #f6f7fc;
      width: 100px;
      height: 180px;
      margin-bottom: 10px;
      color: #a6b6c6;
      .ant-image {
        width: 120px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .no-img1 {
        height: 50px;
        width: 64px;
        margin-bottom: 10px;
      }
    }
    .none-wrap {
      background: #f6f7fc;
      width: 250px;
      height: 110px;
      margin-bottom: 10px;
      color: #a6b6c6;
      .ant-image {
        width: 120px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .no-img {
        height: 50px;
        width: 64px;
        margin-bottom: 10px;
      }
    }
  }
  .imgs {
    width: 100px !important;
    height: 100px !important;
  }
  .imgs1 {
    width: 100% !important;
    height: 100% !important;
  }
  .report-img {
    width: 80px;
  }
  .line {
    border-bottom: 1px solid @border-color-base;
    margin: 24px 0;
  }
  .source-line {
    margin-bottom: 30px;
  }
  .source-line:last-child .icon-wrap.out-icon-wrap::after {
    height: 0;
  }
  .time-wrap {
    width: 100px;
    .date {
      margin-bottom: 2px;
    }
    .time {
      color: #999;
    }
  }
  .icon-wrap {
    margin-right: 30px;
    background: @primary-color;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    position: relative;
  }
  .icon-wrap::after {
    position: absolute;
    top: 40px;
    left: 20px;
    content: "";
    width: 1px;
    height: 140px;
    background-color: @border-color-base;
  }
  .desc-wrap {
    width: 300px;
    .title {
      font-size: 18px;
    }
    .desc {
      color: #999;
      margin-top: 2px;
    }
  }

  .icon-wrap.out-icon-wrap::after {
    height: 120px;
  }
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;