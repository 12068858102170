.line-cont {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
  .cont-tit {
    height: 50px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    .tit-right {
      display: flex;
      flex-flow: row nowrap;
      height: 100%;
      .right-one {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        width: 74px;
        height: 26px;
        background: #5372e3;
        color: #ffffff;
      }
      .right-two {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 74px;
        height: 26px;
        background: #ffffff;
        border: 1px solid #5372e3;
      }
    }
  }
  .line-main {
    height: 100%;
  }
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;