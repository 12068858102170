.background {
  background: #1e1d3a;
  color: white;
  font-size: 14px;
  line-height: 14px;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 !important;
  height: 100%;
  overflow: auto;
}
.blank {
  height: 16px;
  width: 100%;
}
.blankHeng {
  width: 16px;
  height: 100%;
}
.box {
  background: rgba(47, 45, 113, 0.8);
  border: 1px solid rgba(168, 166, 225, 0.4);
  border-radius: 10px;
  padding: 16px;
  box-sizing: border-box;
}

.divBlock {
  box-sizing: border-box;
  position: relative;
  background: #373583;
  border: 1px solid rgba(95, 88, 255, 0.76);
  position: relative;
}

.recBlock {
  height: 44px;
  line-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}

.divBlock::after {
  position: absolute;
  content: "";
  right: -1px;
  top: -1px;
  bottom: -1px;
  width: 10px;
  background-image: url(../../assets//images/board/border4.png),
    url(../../assets//images/board/border3.png);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: right top, right bottom;
}

.divBlock::before {
  position: absolute;
  content: "";
  left: -1px;
  top: -1px;
  bottom: -1px;
  width: 10px;
  background-image: url(../../assets//images/board/border2.png),
    url(../../assets//images/board/border1.png);
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: left top, left bottom;
}

.squareBlock {
  flex: 1;
  height: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  padding-top: 16px;
  box-sizing: border-box;
}

.title {
  font-size: 18px;
  color: #a8a5ff;
  position: relative;
  padding-left: 18px;
  margin-bottom: 26px;
}

.title::before {
  position: absolute;
  content: "";
  left: 0;
  top: -1px;
  width: 6px;
  height: 18px;
  opacity: 1;
  background: #a8a5ff;
  border-radius: 3px;
}

.select {
  width: 100px;
  height: 30px;
  line-height: 30px;
  background: #2b296a;
}

.yellowColor {
  color: #dabe0e;
}

.redColor {
  color: #d42d2f;
}

.purpleColor {
  color: #a8a5ff;
}

.greenColor {
  color: #1eb38b;
}

.blueColor {
  color: #3582fe;
}

.redBackground {
  background: #e84d4f;
}

.yellowBackground {
  background: #dc8320;
}

.blueBackground {
  background: #3582fe;
}

.purpleBackground {
  background: #a8a5ff;
}

.greenBackground {
  background: #1eb38b;
}

.leftSide .recBlock {
  margin-bottom: 10px;
}

.leftSide .squareList {
  display: flex;
  flex-direction: row;
}

.leftSide .squareBlock {
  margin-right: 6px;
}

.leftSide .squareBlock .desc {
  margin-top: 10px;
  text-align: center;
  line-height: 18px;
}

.leftSide .squareBlock:last-child {
  margin-right: 0;
}

.leftSide .box2 {
  box-sizing: border-box;
  height: 346px;
  overflow: hidden;
}

.leftSide .box3 {
  box-sizing: border-box;
  height: 340px;
  overflow: hidden;
}

.flexRow {
  display: flex;
  flex-direction: row;
}

.leftSide .progressItem {
  margin-bottom: 20px;
}

.leftSide .progressLine {
  align-items: flex-start;
}

.leftSide .progress {
  flex: 1;
  line-height: unset !important;
}

.leftSide .progressName {
  font-size: 16px;
  margin-right: 20px;
  line-height: 16px;
  display: inline-block;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.leftSide .lineCenter {
  flex: 1;
  overflow: hidden;
}

.leftSide .progressValue {
  color: #dbd9ff;
  margin-left: 20px;
  width: 40px;
  text-align: right;
}

.leftSide .progressDesc {
  color: #a8a5ff;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.leftSide .rankArrow {
  margin: 0 6px;
  font-size: 18px;
  font-weight: bold;
}

.leftSide .areaScoreList {
  height: 260px;
  overflow: auto;
}

.leftSide .rankList {
  height: 480px;
  overflow: auto;
}

.leftSide .quarantList {
  height: 230px;
  overflow: auto;
}
.leftSide .quarantTitle {
  color: #a8a5ff;
  flex: 1;
  margin-bottom: 16px;
}
.leftSide .quarantTitle2 {
  width: 80px;
  color: #a8a5ff;
  margin-bottom: 16px;
}
.leftSide .quarantTitle3 {
  width: 60px;
  color: #a8a5ff;
  margin-bottom: 16px;
}
.quarantLine {
  margin-bottom: 14px;
}
.leftSide .quarantIndex {
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
  display: inline-block;
}
.leftSide .quarantItem {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 20px;
}
.leftSide .quarantItem2 {
  width: 60px;
  text-align: left;
  display: inline-block;
}
.leftSide .quarantItem3 {
  text-align: left;
  display: inline-block;
  width: 60px;
}
.titleLine {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.titleLine .title {
  margin-bottom: 0;
}

.rankIndex {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
  margin-right: 15px;
}

.rankName {
  flex: 1;
  font-size: 18px;
  line-height: 18px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.rankItem {
  cursor: pointer;
  padding: 15px 0;
}

.center {
  width: 100%;
  box-sizing: border-box;
}

.center .box1 {
  width: 100%;
  box-sizing: border-box;
}

.center .stacList {
  margin: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.center .stacItem {
  font-size: 16px;
}

.center .stacItem .yellowColor {
  font-weight: bold;
  font-size: 40px;
}

.center .stacItem .recBlock {
  margin-top: 16px;
  padding: 0 20px;
  line-height: 40px;
}

.checkLine {
  align-items: center;
}

.checkMore {
  cursor: pointer;
  margin-left: 10px;
  display: inline-block;
  margin-top: 4px;
}

.center .mapContainer {
  box-sizing: border-box;
}

.center .riskBox {
  justify-content: space-between;
}

.center .riskBox1 {
  width: 50%;
  overflow: hidden;
  margin-right: 20px;
  box-sizing: border-box;
}

.center .riskChart {
  width: 100%;
  height: 240px;
}

.dotTitle {
  align-items: center;
}

.dotTitle .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #dbd9ff;
}

.dotTitle .dotBg {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 10px;
  background: rgba(219, 217, 255, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.riskList {
  margin-top: 30px;
}

.riskList .riskItem {
  margin-right: 12px;
}

.riskList .riskItemTitle {
  width: 100px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 6px;
}

.riskList .riskAreaList {
  background: #383684;
  height: 180px;
  overflow: auto;
}

.riskList .riskArea {
  padding: 10px;
  justify-content: space-between;
}

.rightSide .certList {
  justify-content: space-between;
  align-items: center;
}

.rightSide .certList .certItem {
  cursor: pointer;
}

.rightSide .certItem {
  display: flex;
  line-height: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.rightSide .certItem .certName {
  margin-top: 10px;
}
.rightSide .certProgress {
  color: white;
}

.rightSide .circleBox {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 20px 0;
}

.rightSide .circleBg {
  position: absolute;
  top: 4px;
  left: 4px;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background: #2c2971;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSide .certItem .value {
  display: inline-block;
  margin-left: 10px;
}
.rightSide .certItem .certWait {
  margin-bottom: 10px;
}
.rightSide .certItem .certAll {
  padding-bottom: 18px;
}
.rightSide .material .circleBox {
  margin: 0;
  margin-bottom: 10px;
}

.rightSide .material .certItem {
  width: 120px;
}

.rightSide .material {
  justify-content: space-between;
  align-content: flex-start;
}

.rightSide .mTrendWrap {
  flex: 1;
  overflow: hidden;
  margin-left: 30px;
  margin-top: 5px;
}

.rightSide .materialTrend {
  width: 100%;
  height: 80px;
}

.recBlock1 {
  margin-right: 20px;
}

.rightSide .recBlock {
  flex: 1;
  margin-bottom: 10px;
}

.rightSide .recBlock .yellowColor {
  margin-left: 10px;
}

.videoChartWrap {
  width: 40%;
  overflow: hidden;
}

.videoChart {
  width: 100%;
  height: 125px;
  margin-top: 5px;
}

.videoLegendWrap {
  flex: 1;
  overflow: hidden;
  color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.videoLegendItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 8px;
}

.videoLegendSpan {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 14px;
}

.videoLegendText {
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}
.chartItem {
  flex: 1;
  overflow: hidden;
}

.chartItem .deviceChart {
  width: 100%;
  height: 142px;
}
@media screen and (min-width: 1600px) {
  .chartItem .deviceChart {
    width: 100%;
    height: 200px;
  }
}
.chartItem1 {
  margin-right: 20px;
}

.noWrap {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 30px;
}
