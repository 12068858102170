.recommend-wrap {
	height: calc(100% - 50px);
	padding: 12px 20px;
	.recommend-info-tit {
		padding: 16px 20px;
		background: #ffffff;
		border-radius: 5px;
		margin-bottom: 12px;
		.tit-ft {
			font-size: 20px;
			margin-bottom: 18px;
		}
		.tit-cont {
			display: flex;
			flex-flow: row nowrap;
			.tit-cont-img {
				margin-right: 20px;
				.ant-image-img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
			}
			.tit-cont-btm {
				display: flex;
				flex-flow: column nowrap;
				justify-content: flex-start;
				align-items: flex-start;
				.btm-ft-one {
					font-size: 16px;
					margin-bottom: 4px;
				}
				.btm-ft-two {
					color: #778ca2;
					font-size: 12px;
					margin-right: 20px;
				}
			}
		}
	}

	.recommend-main {
		height: calc(100% - 180px);
		background-color: #ffffff;
		border-radius: 5px;
		padding: 20px 20px 40px 20px;
		overflow: auto;
		text-align: center;
		.main-tit {
			margin-bottom: 40px;
			.main-tit-one {
				font-size: 16px;
				margin-bottom: 4px;
			}
			.main-tit-two {
				font-size: 14px;
				color: #778ca2;
			}
		}
	}
	.recommend-wrap {
		margin: 0 auto;
		width: 750px;
		height: 100%;
	}
	table {
		border-top: 1px solid #ccc;
		border-left: 1px solid #ccc;
	}
	table td,
	table th {
		border-bottom: 1px solid #ccc;
		border-right: 1px solid #ccc;
		padding: 3px 5px;
	}
	table th {
		border-bottom: 2px solid #ccc;
		text-align: center;
	}

	/* blockquote 样式 */
	blockquote {
		display: block;
		border-left: 8px solid #d0e5f2;
		padding: 5px 10px;
		margin: 10px 0;
		line-height: 1.4;
		font-size: 100%;
		background-color: #f1f1f1;
	}

	/* code 样式 */
	code {
		display: inline-block;
		*display: inline;
		*zoom: 1;
		background-color: #f1f1f1;
		border-radius: 3px;
		padding: 3px 5px;
		margin: 0 3px;
	}
	pre code {
		display: block;
	}

	/* ul ol 样式 */
	ul,
	ol {
		margin: 10px 0 10px 20px;
	}
	.desc_line {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		.desc_item {
			width: 300px;
		}
	}
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;