.tit-cont {
  display: flex;
  flex-flow: column nowrap;
  .tit-right {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
    margin-left: auto;
    .right-one {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 26px;
      background: #5372e3;
      color: #ffffff;
    }
  }
  .tit-cont-top {
    font-size: 19px;
    margin-bottom: 14px;
  }
  .tit-cont-btm {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    .cont-btm-ct {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      justify-content: center;
      align-items: center;
      &:nth-child(1) {
        border-right: 2px solid #eaeaea;
      }
      &:nth-child(2) {
        border-right: 2px solid #eaeaea;
      }
      .ct-tag {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
        margin-right: 26px;
      }
      .grey-bg {
        background: #eceff8;
      }
      .yellow-bg {
        background: #fff7e9;
      }
      .green-bg {
        background: #e7fafb;
      }
      .btm-ct-ft {
        .btm-ct-ft-top {
          color: #778ca2;
          font-size: 12px;
        }
        .btm-ct-ft-btm {
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;