.detail-wrap{
  .title-line{
    background: #F8F1F2;
    height: 36px;
    padding: 0 29px;
    color: #C75555;
    margin-bottom: 30px;
  }
  .img-block{
    border: 1px solid @border-color-base;
    padding: 5px;
    .ant-image,.img{
      width: 100%;
      height: 140px;
    }
    .name-line{
      padding: 16px 20px;
      color: #252631;
    }
  }
}

.handle-info{
  margin-top: 30px;
  color: #999;
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;