.list-wrap {
  .title-wrap {
    margin-bottom: 20px;

    .tab-wrap {
      margin-right: 50px;
    }
  }

  .img-block {
    cursor: pointer;
    border: 1px solid @border-color-base;

    .ant-image,
    .img {
      width: 100%;
      height: 140px;
    }

    .price-line {
      padding: 2px 20px;
    }

    .price {
      color: #EA5752;
    }

    .name {
      padding: 2px 20px;
      color: #252631;
    }
  }
}

.detail-wrap {
  .detail_info {
    display: flex;
    flex-wrap: wrap;
    .detail_tag {
      margin: 5px 10px 10px 5px;
      background-color: #F6F7FC;
      padding: 10px;
    }
  }

  .line {
    border-bottom: 1px solid @border-color-base;
    margin: 24px 0;
  }

  .desc-wrap {
    background: #F6F7FC;
    padding: 20px;
    color: #778CA2;
  }

  .ant-image,
  .img {
    width: 180px;
    height: 100px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;