.tit-row {
	display: flex;
	flex-flow: row nowrap;
	font-size: 14px;
	.first-tit {
		padding: 4px 18px 4px 0px;
	}
	.ck-active {
		cursor: pointer;
		background-color: #5473e8;
		color: #ffffff;
		padding: 4px 24px;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		margin-bottom: 20px;
		font-size: 14px;
	}
	.default-active {
		cursor: pointer;
		background-color: #e8ebf9;
		color: #333333;
		padding: 4px 24px;
		display: flex;
		flex-flow: row;
		justify-content: center;
		align-items: center;
		margin-right: 15px;
		margin-bottom: 20px;
		font-size: 14px;
	}
}
.emptyShow {
	color: #999;
	text-align: center;
	margin: 100px auto;
}
.wrap-cont {
	display: flex;
	flex-flow: row wrap;
	margin-top: 15px;
	.iot-cont {
		position: relative;
		display: flex;
		flex-flow: column nowrap;
		border: 1px solid #eaeaea;
		margin-bottom: 20px;
		margin-right: 1%;
		.iot-state {
			width: 74px;
			height: 28px;
			position: absolute;
			right: -1px;
			top: -1px;
			display: flex;
			flex-flow: row;
			justify-content: center;
			align-items: center;
		}
		.iot-name {
			padding: 24px 16px;
			display: flex;
			flex-flow: row nowrap;
			height: 100%;
			.iot-img {
				margin-right: 16px;
			}
			.name-adress {
				display: flex;
				flex-flow: column nowrap;
				flex: 1;
				.ft-name {
					font-size: 14px;
					margin-bottom: 4px;
				}
				.ft-adr {
					font-size: 12px;
					color: #778ca2;
				}
				.adr-row {
					display: flex;
					flex-flow: row nowrap;
					margin-top: 10px;
					justify-content: space-between;
					.adr-item {
						display: flex;
						flex-flow: row nowrap;
						.adr-tag {
							background-color: #f8f8f8;
							color: #5473e8;
							font-size: 12px;
							padding: 2px 8px;
							border: 1px solid #c0d3e8;
							border-radius: 5px;
							margin-right: 10px;
						}
						.exp-info {
							color: #e92020;
							font-size: 14px;
						}
					}
				}
			}
		}
		.iotdown {
			height: 58px;
			background: #f8f8f8;
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			align-items: center;
			padding: 0px 16px;
			.down-row {
				display: flex;
				flex-flow: row nowrap;
				.adr-tag {
					background-color: #f8f8f8;
					color: #5473e8;
					font-size: 12px;
					padding: 2px 8px;
					border: 1px solid #c0d3e8;
					border-radius: 5px;
					margin-right: 10px;
				}
				.sdg-tag {
					font-size: 14px;
					margin-right: 10px;
					color: #000000;
				}
				.nm {
					color: #333333;
					font-size: 14px;
					margin-right: 6px;
					font-weight: 500;
				}
				.nm-num {
					font-size: 14px;
				}
				.nm-pip {
					font-size: 14px;
				}
				.air-more {
					color: #999999;
					margin-left: 30px;
					.air-ft {
						margin-right: 3px;
					}
				}
			}
		}
	}
	.green-bg {
		background: #23bcca;
	}
	.grey-bg {
		background: #e8ebf9;
	}
	.white-color {
		color: #ffffff;
	}
	.black-color {
		color: #333333;
	}
	.red-color {
		color: #e92020;
	}
	.green-color {
		color: #56cea5;
	}
	.lightRed-color {
		color: #f85d43;
	}
	.orange-color {
		color: #f89843;
	}
	.blue-color {
		color: #117cef;
	}
	.grey-color {
		color: #999999;
	}
}
@media screen and (max-width: 2700px) {
	.iot-cont {
		width: 19.2%;
		margin-right: 1%;
		&:nth-child(5n) {
			margin-right: 0;
		}
	}
}
@media screen and (max-width: 2300px) {
	.iot-cont {
		width: 23.5%;
		margin-right: 2%;
		&:nth-child(4n + 1) {
			margin-right: 2%;
		}
		&:nth-child(4n + 2) {
			margin-right: 2%;
		}
		&:nth-child(4n + 3) {
			margin-right: 2%;
		}
		&:nth-child(4n) {
			margin-right: 0;
		}
	}
}
@media screen and (max-width: 2040px) {
	.iot-cont {
		width: 32%;
		margin-right: 2%;
		&:nth-child(3n + 1) {
			margin-right: 2%;
		}
		&:nth-child(3n + 2) {
			margin-right: 2%;
		}
		&:nth-child(3n) {
			margin-right: 0;
		}
	}
}
@media screen and (max-width: 1710px) {
	.iot-cont {
		width: 49%;
		margin-right: 2%;
		&:nth-child(3n) {
			margin-right: 2%;
		}
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;