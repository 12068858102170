.background {
  background: #efefef;
  box-sizing: border-box;
  height: calc(100% - 50px);
  padding: 12px;
  margin: 0 !important;
  overflow: auto;
}

.box {
  background: white;
  padding: 20px 10px;
  background: white;
  border-radius: 5px;
  box-sizing: border-box;
}

.leftSide .sumTitle {
  margin-bottom: 0;
}

.select {
  width: 100px;
}

.leftChart {
  width: 160px;
  height: 260px;
  overflow: hidden;
  margin-top: 20px;
  margin-bottom: 30px;
}

.trendBox {
  margin-top: 14px;
  height: 500px;
  box-sizing: border-box;
}

.tableWrap {
  height: 475px;
  overflow: hidden;
}

.trendChart {
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.deviceChartList {
  width: 50%;
  overflow: hidden;
}

.deviceChartList:last-child {
  margin-left: 14px;
}

.deviceChart1,
.deviceChart2 {
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.rightChart {
  width: 100%;
  height: 260px;
  overflow: hidden;
}

.warnList {
  margin-top: 14px;
}

.warnItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
}

.warnItem:nth-child(even) {
  background: #efefef;
}

.warnLeft {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  overflow: hidden;
}

.warnName {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.warnRight {
  width: 50px;
  overflow: hidden;
  margin-left: 5px;
}

.warnType {
  color: #f54729;
  margin-right: 20px;
}

.warnColor {
  color: #f0940ce1;
}

.deviceSum {
  background: #3582FE;
  color: white;
  padding: 6px 5px;
  margin-bottom: 10px;
}

.legendWrap {
  flex: 1;
}

.legendItem {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.legendSpan {
  width: 14px;
  height: 14px;
  border-radius: 2px;
  display: inline-block;
  margin-right: 14px;
}

.legendText {
  overflow: hidden;
  text-overflow: ellipsis;
}

.greenBg {
  background: #1EB38B;
}

.greenColor {
  color: #1EB38B;
}

.redColor {
  color: #e84d4f;
}

.blueBg {
  background: #3582FE;
}

.yellowBg {
  background: #DC8320;
}

.greyBg {
  background: #A5A5A5;
}