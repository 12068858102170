.data-wrap {
	padding: 20px;
}
.zg-container-y {
	height: 100%;
	overflow-y: auto;
	padding: 15px;
}
.icon {
	font-size: 14px;
	color: #999999;
	padding-top: 5px;
}
.card-info {
	.none-wrap {
		width: 100% !important;
	}
}
.base-info-wrap {
	.head-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 14px;
		align-items: center;
		padding: 5px 0;
		color: #a4abc9;
		.data-left {
			background: linear-gradient(to right, #eceff8, #eceff8, #eceff8, #fff);
			padding: 5px 26px;
		}

		.data-right {
			background: linear-gradient(to left, #eceff8, #eceff8, #eceff8, #fff);
			padding: 5px 26px;
		}

		.title {
			display: flex;
			flex: row;
			justify-content: space-between;
			align-items: center;
			font-size: 28px;
			color: #5473e8;

			.line-left {
				margin-right: 20px;
				width: 150px;
				height: 2px;
				background: linear-gradient(
					to left,
					#5372e3,
					#5372e3,
					#eceff8,
					#ffffff,
					#ffffff
				);
				opacity: 0.5;
			}

			.line-right {
				margin-left: 20px;
				width: 150px;
				height: 2px;
				background: linear-gradient(
					to right,
					#5372e3,
					#5372e3,
					#eceff8,
					#ffffff,
					#ffffff
				);
				opacity: 0.5;
			}
		}
	}
}

.today-wrap {
	// height: 400px;
	.today-item {
		margin: 30px 0 48px 0;
	}
	.icon-wrap {
		width: 41px;
		height: 41px;
		background: #eceff8;
		border-radius: 50%;
		text-align: center;
		line-height: 41px;
		.icon {
			font-size: 18px;
			color: #778ca2;
		}
	}
	.info {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		margin-left: 20px;
		font-size: 14px;
		color: #778ca2;
		.data {
			font-size: 18px;
			.red {
				color: @error-color;
			}
		}
	}
}

.inspection-wrap {
	display: flex;
	flex-direction: row;

	.card {
		background: #f6f7fc;
		margin: 0 10px;
		.img {
			height: 157px;
			width: 100%;
		}
		.info {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 14px;
			padding: 9px 17px;
			.red {
				font-size: 14px;
				color: @error-color;
			}
		}
		.msg {
			padding: 0 0 10px 17px;
			font-size: 14px;
			color: #778ca2;
		}
	}
}

.safety-wrap {
	display: flex;
	flex-direction: column;
	color: #252631;
	.item-data {
		display: flex;
		flex-direction: row;
		margin: 10px 0;
		.pro {
			margin: auto;
			padding: 0 7px;
		}
		.pro1 {
			// padding: 0 7px;
			padding-top: 6px;
		}
		.item-right {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			color: #778ca2;
		}
	}
}

.work-wrap {
	padding: 20px;
	background: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	.card {
		padding: 5px 0;
	}
}

.heat-wrap {
	.row-data {
		// margin-top: 30px;
		// margin-bottom: 6px;
		padding: 30px 50px 6px;
		.data {
			font-size: 24px;
			text-align: center;
			.allsum {
				margin-top: 5px;
				color: #778ca2;
				font-size: 14px;
			}
		}
	}
}
.amap-marker-label {
	margin-left: 5px;
	border: 1px solid white !important;
}
.stac-info-wrap1 {
	margin-top: 12px;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;