.chart_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  .title_left {
    width: 6px;
    height: 16px;
    background: rgba(42, 130, 228, 1);
    border-radius: 5px;
    margin-right: 1.5vh;
  }

  .title_text {
    color: rgba(56, 56, 56, 1);
    font-size: 18px;
  }

  .title_right {
    color: rgba(128, 128, 128, 1);
    font-size: 14px;
    margin-left: auto;
  }
}

/**   ------上方为头部 title组件样式------ **/

.statistics_count {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .statistics_left {
    display: flex;
    flex-direction: row;
    align-items: center;

    .count_order {
      padding-left: 18px;
      color: rgba(133, 133, 133, 1);
      font-size: 14px;

      .count_val {
        color: #000000;
        font-size: 24px;
      }

      .count_val span {
        color: rgba(133, 133, 133, 1);
        font-size: 14px;
      }
    }

    .count_icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(42, 130, 228, 1);
    }
  }
}

.statistical_time {
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(194, 194, 194, 1);
  border-radius: 5px;
  padding: 0 0 0 10px;
}

/**   ------上方为订单趋势统计样式------ **/
.statistics_table {
  .table_title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-bottom: 24px;
  }
}


/**   ------上方为商户订单排行榜样式------ **/



.tableData {
  padding: 20px;
  height: calc(100vh - 114px);

  .tableData_content {
    height: 100%;
    padding: 20px;
    background: #fff;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;