.putluck-check-wrap {

  .add-btn-wrap {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .title {
    position: relative;
    padding-left: 10px;
    font-size: 16px;
    line-height: 40px;
    color: #252631;
    margin-top: 35px;
  }

  .green-color {
    color: #67C23A;
  }

  .blue-color {
    color: #5473E8;
  }

  .first-title {
    margin-top: 10px;
  }

  .title::after {
    content: '';
    position: absolute;
    left: 0;
    top: 12.5px;
    width: 4px;
    height: 15px;
    background: #5473E8;
  }

  .info-line {
    margin-top: 20px;
    margin-left: 20px;
  }

  .info-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .info-item {
    width: 300px;
    font-size: 14px;
    line-height: 30px;
  }

  .info-item-con {
    font-weight: 600;
  }

  .ant-image {
    width: 68px;
    height: 68px;
    margin-left: 15px;
  }

  .detail-img {
    height: 100%;
    width: 100%;
  }

  .ant-picker {
    width: 100%;
  }

  .tip {
    font-size: 14px;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .title1 {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .tip1 {
    margin-top: 10px;
  }

  .btn-list {
    margin-top: 35px;

    .btn1 {
      margin-right: 30px;
    }

    .cancel-btn {
      width: 115px;
      background: #E8EBF9;
      color: #778CA2;
    }
  }

  .ant-tabs-tab {
    padding-bottom: 20px;
    margin-right: 40px;
  }

  .ant-tabs {
    margin-bottom: 20px;
  }

  .monad {
    font-size: 14px;
    color: #8e8e8e;

    .canteen_name {
      color: #000000;
      margin-left: 15px;
    }
  }

  .info-left {
    padding-left: 20px;
  }


  .detail-pics1 {
    width: 68px;
    height: 68px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;