.detail-wrap {
	.line {
		border-bottom: 1px solid @border-color-base;
		margin: 24px 0;
	}
	.source-line {
		margin-bottom: 30px;
	}
	.time-wrap {
		margin-right: 60px;
		.date {
			margin-bottom: 2px;
		}
		.time {
			color: #999;
		}
	}
	.desc {
		color: #999;
		margin-top: 6px;
		text-align: center;
	}
	.img-wrap {
		width: 100px;
		margin-top: 20px;
	}
	.img-wrap1 {
		margin: 20px 0;
		width: 200px;
		.ant-image,
		.img {
			width: 100%;
		}
	}
	.label {
		margin-right: 15px;
	}
	.img-list-wrap {
		position: relative;
		.img-list {
			cursor: pointer;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			z-index: 1002;
		}
	}
}
.img-wrap {
	.ant-image {
		height: auto !important;
	}
}
.img-wrap1 {
	.ant-image {
		height: auto !important;
	}
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;