.ranking {
  padding: 20px;
  background: white;
  border-radius: 5px;
}

.heads {
  padding: 15px 0;
  border-radius: 5px;
  padding-left: 20px;

  span {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #252631;
  }

  .compared {
    margin-top: 20px;

    span:first-child {
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #252631;
    }

    .spans {
      padding: 8px 20px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      background-color: #E8EBF9;
      margin-left: 18px;
    }
  }
}

.leaderboard {
  padding: 18px 20px;
  border-radius: 5px;
}

.lists-item {
  display: flex;
  align-items: center;
  padding: 20px 20px;

  .list-row-right {
    margin-left: 36px;
    color: #5473E8;
  }

  .list-row-left {
    display: flex;
    justify-content: center;
    align-items: center;

    .list-left-cln {
      margin-left: 15px;

      .list-left-ft1 {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #252631;
      }

      .list-left-ft2 {
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #778CA2;
      }
    }
  }
}

.list-left-tag {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFFFFF;
}

.blue-bg {
  background: #5473E8;
}

.green-bg {
  background: #4CCCD4;
}

.yellow-bg {
  background: #FF9F24;
}

.grey-bg {
  background: #A6B6C6;
}

//餐饮工作完成情况
.ring {
  width: 42px;
  height: 42px;
  background: #EDF0FA;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
}

.longford {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: 16px;
}

.flexist {
  padding: 20px;
}

.correctionflex {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  background-color: #F6F7FC;
  // padding: 25px 50px;
  margin-top: 20px;
  border-left: 4px solid #5473E8;
  padding: 0px 80px 0px 35px;

  .orrectionfize {
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #252631;
    line-height: 39px;
  }

  .addfize {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #778CA2;
    line-height: 39px;

    span {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #252631;
      line-height: 39px;
    }

    span:last-child {
      margin-left: 16px;
    }
  }
}

//评估分析报告
.roundball {
  width: 193px;
  height: 193px;
  border: 3px solid #E8EBF9;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom-color: #5473E8;

  .roundball_fize {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}



.chart-wrap {
  position: relative;

  .desc-wrap {
    position: absolute;
    top: 35px;
    left: 112px;
    background: #E9ECF7;
    height: 150px;
    width: 150px;
    border-radius: 50%;
    text-align: center;
    padding: 0 auto;

    .chart-number {
      font-size: 28px;
      color: #5473E8;
    }

    .unit {
      font-size: 18px;
      color: #5473E8;
    }

    .chart-desc {
      font-size: 18px;
      color: #5473E8;
    }
  }
}

.tinyflex {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .text_cen {
    text-align: center;
    flex: 1;
    padding: 10px 0;

    .two_color {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #EA5752;
      line-height: 39px;
    }

    .seventeen {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #FF9F24;
      line-height: 39px;
    }

    .dones {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #778CA2;
      line-height: 39px;
    }

    .points {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #252631;
    }
  }
}

.analysis {
  display: flex;
  justify-content: center;
  padding: 20px;

  .statusquo {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #252631;
    line-height: 24px;
  }

  .overall {
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #778CA2;
    line-height: 24px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;