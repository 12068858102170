.zg-container{
  height: calc(100% - 80px);
}
.canteen-list{
  height: 100%;
  padding: 20px 0!important;
  background: #fff;
  overflow: hidden;
  .menu-list{
    width: 100%;
    height: calc(100% - 100px);
    overflow: auto;
  }
  .menu-list::-webkit-scrollbar{
    width: 0;
  }
  .title-level-first{
    padding-left: 20px;
  }
  .search-wrap{
    margin-bottom: 20px;
    padding: 0 20px;
  }
  .ant-menu-submenu{
    background: #F6F7FC!important;
  }
  .ant-menu-inline .ant-menu-submenu-title{
    margin-top: 0!important;
    background: #E8EBF9!important;
    color: #252631!important;
  }
  .ant-menu-item{
    color: #252631!important;
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: #F6F7FC!important;
    color: @primary-color!important;
  }
  // .ant-menu .ant-menu-item::after{
  //   border-right: 0!important;
  //   content: none;
  // }
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
    border-right: 0!important;
  }
  .ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title{
    width: 100%!important;
  }
  .ant-menu-submenu-arrow{
    color: #ccc!important;
  }
}
.index-wrap{
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  &::-webkit-scrollbar{
    width: 0;
  }
}
.stac-info-wrap{
  height: calc(100% - 160px);
  margin-top: 12px;
  .card-wrap{
    color: #778CA2;
    border-radius: 5px;
    cursor: pointer;
    .line1{
      background: #F6F7FC;
      padding: 0px 22px;
      height: 100px;
      border-radius: 5px 5px 0 0;
      .icon{
        font-size: 44px;
      }
      .number{
        font-size: 20px;
        color: #252631;
      }
      .desc{
        color: #A6B6C6;
      }
    }
    .line2{
      padding: 12px 18px;
      background: #E9ECF7;
      border-radius:0 0 5px 5px;
    }
  }
  .row-line{
    margin-bottom: 30px;
    .cell-item{
      height: 50px;
      background: #F6F7FC;
      border-radius: 5px;
      box-sizing: border-box;
      padding:0 14px;
      cursor: pointer;
      .icon{
        font-size: 20px;
        color: #778CA2;
      }
      .icon-wrap{
        width: 34px;
        height: 34px;
        background: #ECEFF8;
        border-radius: 50%;
        text-align: center;
        line-height: 34px;
      }
      .icon-wrap-green{
        background: #E7FAFB;
      }
      .icon-wrap-yellow{
        background: #FFF7E9;
      }
      .tag{
        display: inline-block;
        width: 26px;
        height: 26px;
        color: #fff;
        text-align: center;
        line-height: 26px;
        border-radius: 50%;
      }
      .blue{
        background: @success-color;
      }
      .yellow{
        background: @warning-color;
      }
    }
  }
  .row-line-warn{
    .cell-item{
      height: 50px;
      background: #fff;
      border: 1px solid @border-color-base;
      border-radius: 5px;
      box-sizing: border-box;
      padding:0 14px;
      cursor: pointer;
      .tag{
        color: #252631;
        font-size: 18px;
      }
    }
  }
}
.base-info-wrap{
  .card-wrap{
    position: relative;
  }
  .card-wrap:not(:last-child)::after{
    position: absolute;
    right: 0;
    top: 10px;
    width: 1px;
    height: 30px;
    content: '';
    background: @border-color-base;
  }
  .label{
    color: #778CA2;
    margin-right: 24px;
  }
  .name{
    color:#252631;
    margin-bottom:5px;
    font-size: 16px;
  }
  .flag{
    display: inline-block;
    width: 26px;
    height: 26px;
    color: #fff;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
    margin-left: 16px;
    background: @warning-color;
  }
  .desc-tag1{
    color: #fff;
    padding: 0 10px;
    height: 20px;
    background: @success-color;
  }
  .desc-tag2{
    color: #fff;
    padding: 0 10px;
    height: 20px;
    background: @primary-color;
    margin-left: 8px;
  }
}
.video-wrap{
  .cell-item{
    height: 50px;
    background: #F6F7FC;
    border-radius: 5px;
    box-sizing: border-box;
    padding:0 8px;
    cursor: pointer;
    .icon{
      font-size: 20px;
      margin-right: 4px;
      color: #778CA2;
    }
    .tag{
      display: inline-block;
      width: 26px;
      height: 26px;
      color: #fff;
      text-align: center;
      line-height: 26px;
      border-radius: 50%;
    }
    .blue{
      background: @success-color;
    }
    .yellow{
      background: @warning-color;
    }
  }
}
.score-wrap{
  margin-top: 12px;
  height: calc(100% - 160px);
  background: white;
  .desc-line{
    color: #778CA2;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .desc{
    color: #778CA2;
    margin-bottom: 40px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;