.line {
	border-bottom: 1px solid @border-color-base;
	margin: 24px 0;
}
.card-info {
	.none-wrap {
		background: #f6f7fc;
		width: 250px;
		height: 140px;
		margin-bottom: 10px;
		color: #a6b6c6;
		.no-img {
			height: 50px;
			width: 64px;
			margin-bottom: 10px;
		}
	}
	.img {
		height: 140px;
		margin-bottom: 10px;
	}
	.desc {
		color: #778ca2;
		text-align: center;
	}
	.left-item {
		margin-right: 90px;
	}
}
.right-item .img,
.left-item .img {
	width: auto !important;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;