.leftBox2 {
  box-sizing: border-box;
}
.active {
  background: #373583;
}

.canteenBox {
  height: 654px;
}

.center .box2 {
  width: 100%;
}

.rankList {
  height: 570px;
  overflow: auto;
}

.rankItem {
  padding-left: 10px;
  padding-right: 10px;
}

.boxItem {
  height: 525px;
  flex: 1;
}

.boxItem2 {
  height: 420px;
  width: 50%;
  box-sizing: border-box;
  overflow: hidden;
}
.shipinyujing {
  flex: 1;
}
.wulianyujing {
  box-sizing: border-box;
  flex: 1;
}
.rightSideFlex {
  flex: 1;
}
.videoChartWrap {
  width: 100%;
}

.videoWarn {
  width: 100%;
  box-sizing: border-box;
}
.videoChartHo {
  height: 193px !important;
  width: 100%;
}

.recBlockCenter {
  height: 44px;
  line-height: 44px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
}

.recBlockCenter {
  flex: 1;
  margin-bottom: 20px;
}

.recBlockCenter .yellowColor {
  margin-left: 20px;
  color: #dabe0e;
}

.correctImg {
  width: 55px;
  height: 45px;
}

.correctInfo {
  flex: 1;
  overflow: hidden;
  margin-left: 18px;
}

.correctLeft {
  flex: 1;
  overflow: hidden;
}

.correctName {
  overflow: hidden;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 16px;
  margin-bottom: 16px;
}

.correctDesc {
  overflow: hidden;
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 14px;
}

.correctItem {
  align-items: flex-start;
  justify-content: space-between;
  /* padding-bottom: 24px;
  margin-bottom: 2px; */
}

.materialTrend {
  height: 100px !important;
}

.mbox2 {
  height: 260px;
}

.materialContent {
  margin-top: 40px;
}

.materialContent .circleBox1 {
  margin-bottom: 20px !important;
}

.correctList {
  height: 338px;
  overflow: auto;
}

.chartItem {
  flex: 1;
  overflow: hidden;
}

.chartItem .deviceChart {
  width: 100%;
  height: 180px;
  margin-top: 30px;
}
.chartItem1 {
  margin-right: 20px;
}
