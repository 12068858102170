  .zg-container {
    .summary {
      margin: 20px 100px;
      padding: 20px;
      display: flex;
      font-size: 18px;
     border-radius: 5px;

      .descr {
        font-family: Microsoft YaHei,
          Microsoft YaHei-Normal;
        font-weight: Normal;
        text-align: left;
        color: #333333;
      }

      .size {
        color: #999999;
      }
    }

    .content {
      margin: 20px 100px;
    }

    .title_wrpa {
      font-size: 20px;
      font-family: Microsoft YaHei,
        Microsoft YaHei-Bold;
      font-weight: Bold;
      color: #333333;
      text-align: center;
    }

  }
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;