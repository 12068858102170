.card-license {
  .none-wrap {
    background: #f6f7fc;
    width: 250px;
    height: 140px;
    margin-bottom: 10px;
    color: #a6b6c6;
    .ant-image {
      width: auto !important;
      height: auto !important;
    }

    .no-img {
      height: 50px;
      width: 64px;
      margin-bottom: 10px;
    }
  }
  .ant-image {
    width: auto !important;
    height: auto !important;
  }
  .license_img{
  	  height: 180px;
  	  margin-bottom: 10px;
  }
  .desc {
    color: #778ca2;
    text-align: center;
  }
  .left-item {
    margin-right: 90px;
  }
}

.detail-wrap {
  .faren-title {
    margin-top: 0;
  }

  .shengfenzhengRow {
    text-align: center;

    p {
      margin-top: 15px;
      color: #ccc;
    }

    h5 {
      text-align: left;
      font-size: 14px;
    }
  }

  .line {
    border-bottom: 1px solid @border-color-base;
    margin: 24px 0;
  }

  .source-line {
    margin-bottom: 30px;
  }

  .time-wrap {
    margin-right: 60px;

    .date {
      margin-bottom: 2px;
    }

    .time {
      color: #999;
    }
  }

  .desc {
    color: #999;
    margin-top: 2px;
    text-align: center;
  }

  .label {
    margin-right: 15px;
  }

  .img-list-wrap {
    position: relative;

    .img-list {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;