//button 样式修改
.btn:focus {
	color: #7f9df5 !important;
	background: #fff !important;
	border-color: #7f9df5 !important;
}
.btn:active {
	color: #fff !important;
	background: #7f9df5 !important;
	border-color: #7f9df5 !important;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;