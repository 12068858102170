.dish-wrap{
  padding: 20px;
  height: calc(100% - 50px);
  .dish-main{
    height: calc(100% - 40px);
    padding: 12px 20px 60px 20px;
    background-color: #FFFFFF;
    border-radius: 5px;
    overflow: auto;
    .main-tit{
      font-size: 20px;
      margin-bottom: 12px;
    }
    .main-tab{
      display: flex;
      flex-flow: row nowrap;
      margin-bottom: 30px;
      .main-search{
        margin: 0px 20px 0px 40px;
      }
      .main-btn{
        width: 88px;
        height: 30px;
        background: #5473E8;
        color: #FFFFFF;
        font-size: 14px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
      }
    }
    .dish-list{
      display: flex;
      flex-flow: row wrap;
    }
  }
}
.detail-wrap{
  padding: 0px 16px 20px 16px;
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;