.home-wrap{
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 12px 16px 20px 16px;
  width: 100%;
  height: calc(100% - 50px);
  overflow: auto;
  .wrap-left{
    display: flex;
    flex-flow: column nowrap;
    width: calc(100% - 300px);
    .wrap-left-tit{
      background: #FFFFFF;
      border-radius: 5px;
      padding: 10px 18px 46px 18px;
      margin-bottom: 12px;
    }
    .wrap-left-map{
      display: flex;
      flex-flow: row nowrap;
      .map-line{
        width: calc(100% - 372px);
        background: #FFFFFF;
        border-radius: 5px;
        padding: 12px 18px 20px 18px;
        height: 900px;
        margin-bottom: 20px;
      }
      .map-cake{
        width: 360px;
        background: #FFFFFF;
        border-radius: 5px;
        margin-left: 12px;
        padding: 12px 18px 20px 18px;
        height: 900px;
        margin-bottom: 20px;
        overflow: auto;
      }
    }
  }
  .wrap-right{
    width: 288px;
    margin-left: 12px;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 10px 18px;
    overflow: auto;
    height: 1060px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;