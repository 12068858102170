.dish-item {
	width: 160px;
	height: 210px;
	background: #ffffff;
	margin: 0px 15px 15px 0px;
	.item-img {
		height: 120px;
	}
	.item-cont {
		height: calc(100% - 120px);
		border-bottom: 1px solid #e9ecf7;
		border-left: 1px solid #e9ecf7;
		border-right: 1px solid #e9ecf7;
		padding: 8px 10px;
		.item-cont-top {
			margin-bottom: 6px;
			font-size: 15px;
		}
		.item-cont-btm {
			display: flex;
			flex-flow: row nowrap;
			justify-content: space-between;
			.btm-one {
				color: #ea5752;
			}
			.btm-two {
				color: #778ca2;
			}
		}
	}
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;