html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, '微软雅黑', 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
  color: #252631;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 表格公共样式 */
.ant-table-thead .ant-table-cell {
  background: #F6F7FC !important;
  color: #778CA2 !important;
}

.ant-table-cell {
  padding: 14px 8px !important;
}

/* 输入框placeholder颜色 */
input::-webkit-input-placeholder {
  /*Chrome/Safari*/
  color: #A6B6C6 !important
}

input::-moz-placeholder {
  /*Firefox*/
  color: #A6B6C6 !important
}

input::-ms-input-placeholder {
  /*IE*/
  color: #A6B6C6 !important
}

/* 标题样式 */
/* 一级标题 */
.title-level-first {
  font-size: 18px;
  margin-bottom: 30px;
}

/* 二级标题 */
.title-level-second {
  font-size: 16px;
  margin-bottom: 20px;
}

/* button的点击效果 */
.ant-btn-primary:hover,
.ant-btn-primary:active {
  background: #435CBA !important;
}

.ant-btn-primary:focus {
  background: #5473e8 !important;
}

/* 元素隐藏 */
.hide {
  display: none;
}


.down {
  margin-bottom: 8px;
}