.heads {
	font-size: 20px;
	padding: 15px 0;
	border-radius: 5px;
	padding-left: 20px;
}
.list-top {
	background-color: #ffffff;
}

.fontSize {
	font-size: 14px;
	color: #778ca2;
}
.fontDigital {
	font-size: 24px;
	color: #252631;
}
.borders {
	border-right: 1px solid #eaeaea;
	border-left: 1px solid #eaeaea;
}

.all_flex {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	// margin-top: -20px;
}
.inside {
	font-size: 14px;
	width: 74px;
	height: 26px;
	line-height: 26px;
	text-align: center;
	background: #5372e3;
	color: #ffffff;
}

.all {
	font-size: 14px;
	width: 74px;
	height: 26px;
	line-height: 26px;
	text-align: center;
	background: #ffffff;
	border: 1px solid #5372e3;
}
.chart {
	top: -50px;
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;