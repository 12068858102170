.tableData {
  padding: 20px;



  .tableData_content {
    height: 100%;
    padding: 20px;
    background: #fff;

    .conent {
      .Nostate {
        line-height: 24px;
        padding-left: 20px;
        height: 110px;
        background: rgba(255, 227, 227, 1);
        border: 0.5px solid rgba(255, 0, 0, 1);
        margin-bottom: 36px;

        .state_name {
          color: rgba(212, 48, 48, 1);
          font-size: 16px;
        }
      }

      .state {
        padding-left: 20px;
        line-height: 55px;
        height: 55px;
        background: rgba(237, 255, 254, 1);
        border: 0.5px solid rgba(0, 186, 173, 1);
        margin-bottom: 36px;

        .state_name {
          color: rgba(0, 186, 173, 1);
          font-size: 16px;
        }
      }

      .info_name {
        color: rgba(31, 31, 31, 1);
        font-size: 18px;
      }

      .merchantNumber {
        margin-bottom: 38px;

        .info_Row {
          font-size: 14px;
          color: rgba(0, 0, 0, 1);

          :global {
            .ant-col {
              border-right: 0.5px solid #a6a6a6;
              border-bottom: 0.5px solid #a6a6a6;
            }
          }

          .info_Color {
            color: rgba(56, 56, 56, 1);
            background: rgba(239, 244, 248, 1);
          }

          .info_Col {
            height: 55px;
            line-height: 55px;
            padding-left: 26px;
            // border: 0.5px solid rgba(166, 166, 166, 1);
          }
        }

      }
    }
  }

  .border_top {
    border-top: 0.5px solid #a6a6a6;
    border-left: 0.5px solid #a6a6a6;
  }



  .state_name {
    color: rgba(56, 56, 56, 1);
    font-size: 14px;
    padding-bottom: 5px;
  }

  .title_basic {
    display: flex;
    flex-direction: row;

    .title_img {
      padding-right: 15px;

      img {
        border-radius: 5px;
      }
    }

    .title_info {
      color: rgba(128, 128, 128, 1);
      font-size: 12px;
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;