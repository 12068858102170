.stac-info-wrap1 {
	height: 100%;
}

.zg-card {
	background: white;
}

.zg-container-y-x {
	// height: 100%;
	overflow-y: auto;
	padding: 15px;
}

.work-wrap {
	padding: 20px;
	background: white;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	.card {
		padding: 5px 0;
	}
}

//earlyanalysis 新增样式
.addfize {
	width: 150px;
}

.card {
	display: flex;
	flex-direction: column;

	.select {
		display: flex;
		flex-direction: row;
		color: #778ca2;
		cursor: pointer;

		a {
			color: #778ca2;
			text-decoration: none;
		}

		a:hover {
			color: #252631;
			text-decoration: none;
		}

		.black-color {
			color: #000000;
		}

		.sel-left {
			padding: 0 10px 0 50px;
		}

		.sel-con {
			padding: 0 10px;
			border-left: 1px solid #778ca2;
			border-right: 1px solid #778ca2;
		}

		.sel-right {
			padding: 0 10px;
		}
	}

	.desc {
		margin-top: 10px;
		font-size: 14px;
		color: #778ca2;
		overflow: hidden;

		text-overflow: ellipsis;

		display: -webkit-box;

		-webkit-line-clamp: 3;

		-webkit-box-orient: vertical;

		span {
			color: #252631;
		}
	}
}

.base-info-wrap1 {
	.head-wrap {
		display: flex;
		flex-direction: column;

		.title {
			font-size: 24px;
		}

		.line1 {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-top: 30px;

			.line-left {
				font-size: 16px;
			}

			.line-content {
				font-size: 14px;
				display: flex;
				flex-direction: row;

				.bott {
					margin: 0 10px;
					padding: 6px 18px;
					background: #e8ebf9;
					text-align: center;
					cursor: pointer;
					display: flex;
					align-items: center;
				}

				.active {
					background: #5473e8;
					color: #ffffff;
				}
			}
		}
	}
}

.card {
	.chart-wrap {
		position: relative;

		.desc-wrap {
			position: absolute;
			// top: 35px;
			// left: 90px;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background: #e9ecf7;
			height: 150px;
			width: 150px;
			border-radius: 50%;
			text-align: center;
			padding: 0 auto;

			.chart-number {
				font-size: 28px;
				color: #5473e8;
			}

			.unit {
				font-size: 18px;
				color: #5473e8;
			}

			.chart-desc {
				font-size: 18px;
				color: #5473e8;
			}
		}
	}
}

.risk-any-wrap {
	height: calc(100vh - 116px);
	padding: 12px;
	box-sizing: border-box;
}

.risk-any-content {
	height: calc(100% - 154px);
	overflow: hidden;
	margin-top: 12px;
}

.wrap-right {
	height: 100%;
	box-sizing: border-box;
}

.risk-any-content .wrap-right .zg-card {
	height: 100%;
	border-radius: 5px;

	.rank-cont {
		height: 100%;
		border-radius: 5px;

		.title-level-second {
			padding: 0 20px;
			padding-top: 20px;
		}

		.rank-list {
			height: calc(100% - 45px);
			overflow: auto;

			/*y轴滚动*/
			.list-item {
				cursor: pointer;
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				align-items: center;
				padding: 20px 20px;

				.list-row-left {
					display: flex;
					flex-flow: row nowrap;

					.list-left-tag {
						width: 22px;
						height: 22px;
						border-radius: 50%;
						color: #ffffff;
						display: flex;
						flex-flow: row nowrap;
						justify-content: center;
						align-items: center;
						margin-top: 4px;
						margin-right: 16px;
						font-size: 16px;
					}

					.blue-bg {
						background: #5473e8;
					}

					.green-bg {
						background: #4cccd4;
					}

					.yellow-bg {
						background: #ff9f24;
					}

					.grey-bg {
						background: #a6b6c6;
					}

					.list-left-cln {
						display: flex;
						flex-flow: column;

						.list-left-ft1 {
							font-size: 14px;
							margin-bottom: 6px;
						}

						.list-left-ft2 {
							font-size: 12px;
						}
					}
				}

				.list-row-right {
					font-size: 14px;
					font-weight: 400;
					color: #5473e8;
				}
			}
		}

		.active {
			background: #f6f7fc;
			border-right: 2px solid #a4abc9;
		}
	}
}

.complete-card-box .flexist {
	width: 20%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.complete-line-box {
	height: 300px;
}

.complete-line {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;

	.label {
		width: 100px;
		color: #000000;
	}

	.values {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
		color: blue;
		flex: 1;
	}

	.bg-values {
		background: #ddd;
		height: 10px;
	}

	.tag {
		width: 30px;
		height: 10px;
	}

	.tag1 {
		background: #ff9f24;
	}

	.tag2 {
		background: #5473e8;
	}

	.tag3 {
		background: #67cc99;
	}
}

@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;