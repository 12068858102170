.score {
  display: flex;
  align-items: center;
  flex-direction: row;

  .title {
    padding-right: 18px;
    color: rgba(56, 56, 56, 1);
    font-size: 18px;
  }

  .biaoshi {
    width: 6px;
    height: 16px;
    background: rgba(0, 186, 173, 1);
    border-radius: 5px;
    margin-right: 1.5vh;
  }

  .day {
    color: rgba(128, 128, 128, 1);
    font-size: 14px;
    margin-left: auto;
  }
}

.scoreList {
  height: calc(100% - 45px);
  overflow: auto;

  .scoreTag {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
  }

  .listItem {
    padding-left: 12px;
  }

  .listTitle {
    color: rgba(0, 0, 0, 1);
    font-size: 16px;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;