.zg-img{
  width: 280px;
  margin:0px 15px 15px 0px;
  .img-hg{
    height: 144px;
  }
  .zg-img-cont{
    padding: 10px 12px;
    border: 1px solid #E9ECF7;
    .img-cont-tit{
      margin-bottom: 4px;
    }
    .img-cont-btm{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;