.iot-wrap{
  display: flex;
  flex-flow: row nowrap;
  padding: 20px 15px;
  height: calc(100% - 50px);
  .iot-left{
    width: 290px;
    background-color: #FFFFFF;
    border-radius: 5px;
    margin-right: 10px;
    height: calc(100% - 40px);
  }
  .iot-right{
    padding: 30px 20px;
    background-color: #FFFFFF;
    border-radius: 5px;
    height: calc(100% - 40px);
    width: calc(100% - 300px);
    overflow: auto;
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;