.detail-wrap{
  .line{
    border-bottom: 1px solid @border-color-base;
    margin: 24px 0;
  }
  .check-line{
    margin-bottom: 10px;
  }
  .time-wrap{
    margin-right: 60px;
    .date{
      margin-bottom: 2px;
    }
    .time{
      color: #999;
    }
  }
  .icon-wrap{
    margin-right: 30px;
    background: @primary-color;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    color: #fff;
    position: relative;
  }
  .icon-wrap::after{
    position: absolute;
    top: 40px;
    left: 20px;
    content:"";
    width: 1px;
    height: 60px;
    background-color: @border-color-base;
  }
  .desc-wrap{
    .title{
      font-size: 18px;
    }
    .desc{
      color: #999;
      margin-top: 2px;
    }
  }
}
@primary-color: #5473E8;@success-color: #23BCCA;@warning-color: #FF9F24;@error-color: #ff0000;@font-size-base: 14px;@disabled-color: #ccc;@border-radius-base: 5px;@border-color-base: #E8EBF9;